@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-y: auto;
}

.app {
  display: flex;
  position: relative;
}

.login-button {
  display: block;
  padding: 10px;
  background-color: rgb(20, 27, 45);
  color: white;
  border-radius: 5px;
}

.btn-primary {
  display: block;
  padding: 10px;
  background-color: rgb(20, 27, 45);
  color: white;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

aside.ps-sidebar-root {
  height: 100% !important;
}






body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
